<template>
  <app-layout>
    <transition name="component-fade" mode="out-in">
      <div
        v-if="showSupplierForm"
        class="flex flex-col items-center py-4"
        key="form">
        <div class="w-full lg:w-1/2">
          <div class="flex flex-row items-center inline py-4">
            <sa-icon-button
              iconClass="bx bx-left-arrow-alt"
              @click="closeSupplierForm"
              with-bg />
            <span class="mx-2 text-lg font-bold">Proveedor</span>
          </div>
          <content-card>
            <supplier-form
              v-if="isNewSupplier"
              @onSaved="savedSupplier"
              @onCancel="closeSupplierForm" />
            <supplier-form
              v-if="supplierToUpdate && !isNewSupplier"
              :supplier="supplierToUpdate"
              @onSaved="savedSupplier"
              @onCancel="closeSupplierForm" />
          </content-card>
        </div>
      </div>
      <div
        v-if="!showSupplierForm"
        class="flex flex-col items-center py-4"
        key="list">
        <div class="w-full px-12">
          <div class="flex flex-row items-center py-4">
            <h3 class="text-lg font-bold">Proveedores</h3>
            <div class="ml-auto">
              <sa-button
                type="primary"
                class="items-center px-3 py-3 font-bold rounded"
                @click="createSupplier">
                Crear Proveedor
              </sa-button>
            </div>
          </div>
          <content-card>
            <suppliers-list @onEdit="updateSupplier"/>
          </content-card>
        </div>
      </div>
    </transition>
  </app-layout>
</template>
<script>

export default {
  name: 'SuppliersIndex',
  components: {
    SupplierForm: () => import('./SupplierForm.vue'),
    SuppliersList: () => import('./SuppliersList.vue'),
    ContentCard: () => import('@/components/molecules/ContentCard.vue'),
    AppLayout: () => import('@/components/layouts/AppLayout.vue'),
  },
  data() {
    return {
      showSupplierForm: false,
      isNewSupplier: false,
      supplierToUpdate: null,
    };
  },
  methods: {
    savedSupplier() {
      this.showSupplierForm = false;
      this.$toastr.s(this.isNewSupplier ? 'Se creo con exito' : 'Se actualizo con exito');
    },
    updateSupplier(supplierToUpdate) {
      this.supplierToUpdate = supplierToUpdate;
      this.showSupplierForm = true;
    },
    createSupplier() {
      this.isNewSupplier = true;
      this.showSupplierForm = true;
    },
    closeSupplierForm() {
      this.showSupplierForm = false;
      this.isNewSupplier = false;
      this.supplierToUpdate = null;
    },
  },
}
</script>
<style scoped>
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .2s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>
